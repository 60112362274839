import axios from "axios";

export default {
  namespaced: true,

  state: {
    userData: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
  },

  getters: {
    user: (state) => state.userData,
  },

  mutations: {
    setUserData(state, user) {
      state.userData = user;
    },
  },

  actions: {
    sendLoginRequest({ commit }, data) {
      if (data.email.trim() == "" && data.password.trim() == "") {
        return false;
      }
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "login", data)
        .then((response) => {
          commit("setUserData", response.data.user);
          localStorage.setItem("authToken", response.data.code);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          return response;
        });
    },
    sendLogoutRequest({ commit }) {
      axios.post(process.env.VUE_APP_API_URL + "logout").then(() => {
        commit("setUserData", null);
        localStorage.removeItem("authToken");
        window.location.reload();
      });
    },
  },
};
