<template>
  <v-app>
    <!-- -->
    <v-sheet class="overflow-hidden" style="position: relative;">
      <v-main>
        <v-container fluid>
          <router-view ref="rview"></router-view>
        </v-container>
      </v-main>

      <v-navigation-drawer v-model="drawer" app v-if="user">
        <v-list-item>
          <v-list-item-avatar>
            <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <span class="font-weight-medium">{{ user.name }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            link
            v-show="isUserPermission(item.perms)"
            :disabled="item.isdisable"
            v-on:click="removeNotify(index)"
            :to="item.url"
          >
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <span>{{ item.title }}</span>
                <v-badge
                  v-if="item.notify"
                  color="red"
                  class="ml-2"
                  right
                  :content="item.notifyCount"
                >
                </v-badge>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-sheet>

    <v-app-bar app>
      <v-btn color="pink" dark @click.stop="drawer = !drawer" v-if="user">
        <v-icon>
          {{ drawer ? "mdi-menu" : "mdi-close" }}
        </v-icon>
      </v-btn>
      <v-btn class="ml-2" dark @click="toggleTheme" v-if="user">
        <v-icon>
          {{
            isLightTheme
              ? "mdi-moon-waning-crescent"
              : "mdi-white-balance-sunny"
          }}
        </v-icon>
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="red"
            class="ml-2"
            dark
            @click="$router.push({ name: 'Home' })"
            v-if="user"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-broadcast</v-icon> {{ onlineUsers.length }}
          </v-btn>
        </template>
        <span>Online User Count</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            class="ml-2"
            dark
            @click="$router.push({ name: 'Home' })"
            v-if="user"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-broadcast</v-icon> {{ onlineMembers.length }}
          </v-btn>
        </template>
        <span>Online Member Count</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="orange"
            class="ml-2"
            dark
            href="https://gowawe.com/"
            v-if="user"
            target="_blank"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-home-floor-g</v-icon>
          </v-btn>
        </template>
        <span>Gowawe.com</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-btn fab @click="logout" elevation="0" class="ml-2">
        <v-icon>mdi-power</v-icon>
      </v-btn>

      <h5 v-if="!user">Please Login</h5>
    </v-app-bar>

    <v-footer app>
      <span class="d-block text-center w-100"
        >Gowawe Awakens v1 © 2022 It is a Gowawe.com Product.
      </span>
    </v-footer>

    <v-bottom-sheet v-model="connection.statu" persistent>
      <v-sheet class="text-center" height="200px">
        <div class="pt-5">
          <v-progress-circular
            v-if="connection.isconnect == false"
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <v-icon v-if="connection.isconnect == true" color="green" x-large
            >mdi-check</v-icon
          >
          <h5 class="mt-3">{{ connection.text }}</h5>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import menuJson from "./helpers/menuJson";

export default {
  name: "App",
  data: () => ({
    connection: {
      statu: true,
      isconnect: false,
      text: "Connection..",
      delay: 3000,
    },
    drawer: true,
    items: menuJson.index(),
    isLightTheme: false,
    onlineMembers: [],
    onlineUsers: [],
    newUser: null,
    userx: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
  }),
  methods: {
    isUserPermission(item) {
      return this.userx?.permissions.find((a) => {
        if (a.name == item) {
          return true;
        } else {
          return false;
        }
      });
    },
    toggleTheme: function() {
      this.isLightTheme = !this.isLightTheme;
      this.$vuetify.theme.dark = this.isLightTheme;
    },
    removeNotify: function(indis) {
      const ccElement = this.items[indis];
      ccElement.notify = false;
      ccElement.notifyCount = 0;

      const ls = localStorage.getItem("type_notify");
      if (ls != null) {
        const getDataParse = JSON.parse(ls);
        const newDataParse = getDataParse.splice(indis, 1);
        localStorage.setItem("type_notify", JSON.stringify(newDataParse));
      }
    },
    logout: () => {
      localStorage.removeItem("authToken");
      localStorage.removeItem("user");
      window.location.reload();
    },
    realTimeAction: function(item, type) {
      item.notify = true;
      item.sound.play();
      item.notifyCount++;

      if (type == "RFQ" && this.$route.name == "Rfq") {
        this.$refs.rview.getDataFromApi(0);
      }
      if (type == "Products" && this.$route.name == "Products") {
        this.$refs.rview.getDataFromApi(0);
      }
      if (type == "RFQ Offers" && this.$route.name == "RfqOffers") {
        this.$refs.rview.getDataFromApi(0);
      }
    },
  },
  sockets: {
    sendNotifyPanel(data) {
      // data
    },
    usersGowaweSet: (data) => {
      // data
    },
    userCount: (data) => {
      // data
    },
    connect: function() {
      //console.log("socket connected");
      setTimeout(() => {
        this.connection = {
          statu: true,
          isconnect: true,
          text: "Connected",
          delay: 3000,
        };
        setTimeout(() => {
          this.connection.statu = false;
        }, this.connection.delay);
      }, 1000);
    },
    disconnect: function() {
      this.connection = {
        statu: true,
        isconnect: false,
        text: "No internet, Check your internet connection.",
      };
      //this.loadingText
    },
  },
  mounted() {
    this.sockets.subscribe("usersGowaweSet", (data) => {
      this.onlineMembers = data;
    });
    this.sockets.subscribe("userCount", (data) => {
      this.onlineUsers = data.reverse();
    });
    this.sockets.subscribe("sendNotifyPanel", (data) => {
      const type = data.type;
      const ls = localStorage.getItem("type_notify");
      if (ls == null || JSON.parse(ls).length == 0) {
        const newDataArray = [];
        const objNotify = {
          name: type,
          count: 1,
        };
        newDataArray.push(objNotify);
        localStorage.setItem("type_notify", JSON.stringify(newDataArray));
      } else {
        const ls = localStorage.getItem("type_notify");
        const getDataParseN = JSON.parse(ls);
        getDataParseN.find((el) => (el.name == type ? el.count++ : null));
        localStorage.setItem("type_notify", JSON.stringify(getDataParseN));
      }
      const thx = this;
      this.items.find((el) =>
        el.title == type ? thx.realTimeAction(el, type) : null
      );
    });
    const ls = localStorage.getItem("type_notify");
    if (ls != null) {
      const newDataArray = JSON.parse(ls);
      newDataArray.forEach((item) => {
        this.items.find((el) =>
          el.title == item.name
            ? (el.notify = true) &&
              el.sound.play() &&
              (el.notifyCount = item.count)
            : null
        );
      });
    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
