import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";

Vue.use(VueRouter);

const guest = (to, from, next) => {
  if (!localStorage.getItem("authToken") && !localStorage.getItem("user")) {
    return next();
  } else {
    return next("/");
  }
};
const auth = (to, from, next) => {
  if (localStorage.getItem("authToken") && localStorage.getItem("user")) {
    axios
      .get(
        process.env.VUE_APP_API_URL +
          "me?api_token=" +
          localStorage.getItem("authToken")
      )
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem("user", JSON.stringify(res.data.user));
        }
      })
      .catch((err) => {
        console.log(err);
        return next("/login");
      });

    const user = JSON.parse(localStorage.getItem("user"));
    const uperms = user.permissions;
    const thepage = to.name;
    const gpage = routes.find((a) => a.name == thepage).perms;

    uperms.find((a) => {
      if (a.name == gpage) {
        return next();
      }
    });
  } else {
    return next("/login");
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    beforeEnter: auth,
    perms: "homepage",
    component: () => import("../views/Pages/Home.vue"),
  },
  {
    path: "/live/:id",
    name: "Live",
    beforeEnter: auth,
    perms: "live",
    component: () => import("../views/Pages/Live.vue"),
  },
  {
    path: "/visits",
    name: "Visits",
    beforeEnter: auth,
    perms: "visits",
    component: () => import("../views/Pages/Visits.vue"),
  },
  {
    path: "/products/:memberid?",
    name: "Products",
    beforeEnter: auth,
    perms: "products",
    component: () => import("../views/Pages/Products.vue"),
  },
  {
    path: "/products/edit/:id",
    name: "ProductsEdit",
    beforeEnter: auth,
    perms: "products",
    component: () => import("../views/Pages/ProductsEdit.vue"),
  },
  {
    path: "/statistics",
    name: "Statistics",
    beforeEnter: auth,
    perms: "statistics",
    component: () => import("../views/Pages/Statistics.vue"),
  },
  {
    path: "/rfq",
    name: "Rfq",
    beforeEnter: auth,
    perms: "rfq",
    component: () => import("../views/Pages/Rfq.vue"),
  },
  {
    path: "/rfq/edit/:id",
    name: "RfqEdit",
    beforeEnter: auth,
    perms: "rfq",
    component: () => import("../views/Pages/RfqEdit.vue"),
  },
  {
    path: "/members",
    name: "Members",
    beforeEnter: auth,
    perms: "members",
    component: () => import("../views/Pages/Members.vue"),
  },
  {
    path: "/members/edit/:id/:e1?",
    name: "MembersEdit",
    beforeEnter: auth,
    perms: "members",
    component: () => import("../views/Pages/MembersEdit.vue"),
  },
  {
    path: "/members/edit/:id/detail/visit/:vid",
    name: "MembersVisitDetail",
    beforeEnter: auth,
    perms: "members",
    component: () => import("../views/Components/Members/VisitDetail.vue"),
  },
  {
    path: "/weekly-deals",
    name: "Weekly",
    beforeEnter: auth,
    perms: "weekly",
    component: () => import("../views/Pages/Weekly.vue"),
  },
  {
    path: "/reports-problem",
    name: "ReportProblems",
    beforeEnter: auth,
    perms: "reportproblems",
    component: () => import("../views/Pages/ReportProblems.vue"),
  },
  {
    path: "/store-verify",
    name: "StoreVerify",
    beforeEnter: auth,
    perms: "storeverify",
    component: () => import("../views/Pages/StoreVerify.vue"),
  },
  {
    path: "/store-verify/:id",
    name: "StoreVerifyShow",
    beforeEnter: auth,
    perms: "storeverify",
    component: () => import("../views/Pages/StoreVerifyShow.vue"),
  },
  {
    path: "/48h-deals",
    name: "Deals48h",
    beforeEnter: auth,
    perms: "deals48h",
    component: () => import("../views/Pages/Deals48h.vue"),
  },
  {
    path: "/rfq-offers/:id?",
    name: "RfqOffers",
    beforeEnter: auth,
    perms: "qoffers",
    component: () => import("../views/Pages/RfqOffers.vue"),
  },
  {
    path: "/rfq-offers/edit/:id",
    name: "RfqOffersEdit",
    beforeEnter: auth,
    perms: "qoffers",
    component: () => import("../views/Pages/RfqOffersEdit.vue"),
  },
  {
    path: "/orders",
    name: "Orders",
    beforeEnter: auth,
    perms: "orders",
    component: () => import("../views/Pages/Orders.vue"),
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    beforeEnter: auth,
    perms: "subscriptions",
    component: () => import("../views/Pages/Subscriptions.vue"),
  },
  {
    path: "/subscriptions/edit/:id",
    name: "SubscriptionsEdit",
    beforeEnter: auth,
    perms: "subscriptions",
    component: () => import("../views/Pages/SubscriptionsEdit.vue"),
  },
  {
    path: "/categories",
    name: "Categories",
    beforeEnter: auth,
    perms: "categories",
    component: () => import("../views/Pages/Categories.vue"),
  },
  {
    path: "/categories/edit/:id",
    name: "CategoriesEdit",
    beforeEnter: auth,
    perms: "categories",
    component: () => import("../views/Pages/CategoriesEdit.vue"),
  },
  {
    path: "/coupons",
    name: "Coupons",
    beforeEnter: auth,
    perms: "coupons",
    component: () => import("../views/Pages/Coupons.vue"),
  },
  {
    path: "/coupons/edit/:id",
    name: "CouponsEdit",
    beforeEnter: auth,
    perms: "coupons",
    component: () => import("../views/Pages/CouponsEdit.vue"),
  },
  {
    path: "/codes",
    name: "Codes",
    beforeEnter: auth,
    perms: "codes",
    component: () => import("../views/Pages/Codes.vue"),
  },
  {
    path: "/top-factories",
    name: "TopFactories",
    beforeEnter: auth,
    perms: "topfactories",
    component: () => import("../views/Pages/TopFactories.vue"),
  },
  {
    path: "/inbox",
    name: "Inbox",
    beforeEnter: auth,
    perms: "inbox",
    component: () => import("../views/Pages/Inbox.vue"),
  },
  {
    path: "/site-settings",
    name: "SiteSettings",
    beforeEnter: auth,
    perms: "settings",
    component: () => import("../views/Pages/SiteSettings.vue"),
  },
  {
    path: "/cms",
    name: "Cms",
    beforeEnter: auth,
    perms: "cms",
    component: () => import("../views/Pages/Cms.vue"),
  },
  {
    path: "/cms/edit/:id",
    name: "CmsEdit",
    beforeEnter: auth,
    perms: "cms",
    component: () => import("../views/Pages/CmsEdit.vue"),
  },
  {
    path: "/faq",
    name: "Faq",
    beforeEnter: auth,
    perms: "faq",
    component: () => import("../views/Pages/Faq.vue"),
  },
  {
    path: "/faq/new",
    name: "FaqNew",
    beforeEnter: auth,
    perms: "faq",
    component: () => import("../views/Pages/FaqEdit.vue"),
  },
  {
    path: "/faq/edit/:id",
    name: "FaqEdit",
    beforeEnter: auth,
    perms: "faq",
    component: () => import("../views/Pages/FaqEdit.vue"),
  },
  {
    path: "/supplier-types",
    name: "SupplierTypes",
    beforeEnter: auth,
    perms: "settings",
    component: () => import("../views/Pages/SupplierTypes.vue"),
  },
  {
    path: "/currencies",
    name: "Currencies",
    beforeEnter: auth,
    perms: "currencies",
    component: () => import("../views/Pages/Currencies.vue"),
  },
  {
    path: "/currencies/edit/:id",
    name: "CurrenciesEdit",
    beforeEnter: auth,
    perms: "currencies",
    component: () => import("../views/Pages/CurrenciesEdit.vue"),
  },
  {
    path: "/currencies/new",
    name: "CurrenciesNew",
    beforeEnter: auth,
    perms: "currencies",
    component: () => import("../views/Pages/CurrenciesNew.vue"),
  },
  {
    path: "/languages",
    name: "Languages",
    beforeEnter: auth,
    perms: "languages",
    component: () => import("../views/Pages/Languages.vue"),
  },
  {
    path: "/languages/edit/:id",
    name: "LanguagesEdit",
    beforeEnter: auth,
    perms: "languages",
    component: () => import("../views/Pages/LanguagesEdit.vue"),
  },
  {
    path: "/admins",
    name: "Admins",
    beforeEnter: auth,
    perms: "admins",
    component: () => import("../views/Pages/Admins.vue"),
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: guest,
    component: () => import("../views/Auth/Login.vue"),
  },
  {
    path: "*",
    name: "404NotFound",
    redirect: {
      name: "Home",
    },
  },
  {
    path: "/search-history",
    name: "Search History",
    beforeEnter: auth,
    perms: "members",
    component: () => import("../views/Pages/SearchHistory.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes,
});

export default router;
