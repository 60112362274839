export default {
  name: "menuJson",
  index() {
    return [
      {
        title: "Home",
        url: "/",
        notify: false,
        isdisable: false,
        perms: "homepage",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Visits",
        url: "/visits",
        notify: false,
        isdisable: false,
        perms: "visits",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Products",
        url: "/products",
        notify: false,
        isdisable: false,
        perms: "products",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Categories",
        url: "/categories",
        notify: false,
        isdisable: false,
        perms: "categories",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Statistics",
        url: "/statistics",
        notify: false,
        isdisable: false,
        perms: "statistics",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Weekly Deals",
        url: "/weekly-deals",
        notify: false,
        isdisable: false,
        perms: "weekly",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Reports",
        url: "/reports",
        notify: false,
        isdisable: true,
        perms: "reports",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Reports Problem",
        url: "/reports-problem",
        notify: false,
        isdisable: false,
        perms: "reportproblems",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Store Verify",
        url: "/store-verify",
        notify: false,
        isdisable: false,
        perms: "storeverify",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "48H Deals",
        url: "/48h-deals",
        notify: false,
        isdisable: false,
        perms: "deals48h",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "RFQ",
        url: "/rfq",
        notify: false,
        isdisable: false,
        perms: "rfq",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "RFQ Offers",
        url: "/rfq-offers",
        notify: false,
        isdisable: false,
        perms: "qoffers",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Orders",
        url: "/orders",
        notify: false,
        isdisable: true,
        perms: "orders",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Subscriptions",
        url: "/subscriptions",
        notify: false,
        isdisable: false,
        perms: "subscriptions",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Reviews",
        url: "/reviews",
        notify: false,
        isdisable: true,
        perms: "*",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Coupons",
        url: "/coupons",
        notify: false,
        isdisable: false,
        perms: "coupons",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Codes",
        url: "/codes",
        notify: false,
        isdisable: false,
        perms: "codes",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Top Factories",
        url: "/top-factories",
        notify: false,
        isdisable: true,
        perms: "topfactories",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Inbox",
        url: "/inbox",
        notify: false,
        isdisable: false,
        perms: "inbox",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Members",
        url: "/members",
        notify: false,
        isdisable: false,
        perms: "members",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Search History",
        url: "/search-history",
        notify: false,
        isdisable: false,
        perms: "members",
        notifyCount: 0,
      },
      {
        title: "Site Settings",
        url: "/site-settings",
        notify: false,
        isdisable: true,
        perms: "settings",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Hompage Settings",
        url: "/homepage-settings",
        notify: false,
        isdisable: true,
        perms: "homepage",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "CMS",
        url: "/cms",
        notify: false,
        isdisable: false,
        perms: "cms",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Static Page",
        url: "/static-page",
        notify: false,
        isdisable: true,
        perms: "staticpage",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "FAQ",
        url: "/faq",
        notify: false,
        isdisable: false,
        perms: "faq",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Menus",
        url: "/menus",
        notify: false,
        isdisable: true,
        perms: "*",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Attributes Manager",
        url: "/Store Field",
        notify: false,
        isdisable: true,
        perms: "*",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Supplier Types",
        url: "/supplier-types",
        notify: false,
        isdisable: false,
        perms: "settings",

        notifyCount: 0,
      },
      {
        title: "Currencies",
        url: "/currencies",
        notify: false,
        isdisable: false,
        perms: "currencies",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Languages",
        url: "/languages",
        notify: false,
        isdisable: false,
        perms: "languages",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Countries",
        url: "/countries",
        notify: false,
        isdisable: true,
        perms: "countries",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Admins",
        url: "/admins",
        notify: false,
        isdisable: false,
        perms: "admins",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
      {
        title: "Auditing",
        url: "/auditing",
        notify: false,
        isdisable: true,
        perms: "auditing",
        sound: new Audio("./assets/new_product.wav"),
        notifyCount: 0,
      },
    ];
  },
};
