import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueSocketIO from "vue-socket.io";

import VueRouter from "vue-router";
import vuetify from "./plugins/vuetify";
import VCalendar from "v-calendar";

Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import Lightbox from "@morioh/v-lightbox/dist/lightbox";

import "@morioh/v-lightbox/dist/lightbox.css";
import "tiptap-vuetify/dist/main.css";
import "vuetify/dist/vuetify.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

if (window.Vue) {
  window.Vue.use(VueRouter);
}

Vue.use(Lightbox);

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "md",
});

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

Vue.use(require("vue-moment"));

Vue.use(
  new VueSocketIO({
    connection: "wss://chat.gowawe.com",
    // connection: "ws://192.168.1.159:3000",
    withCredentials: true,
    options: {
      transports: ["polling"],
    },
  })
);

Vue.config.productionTip = false;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 422) {
      store.commit("setErrors", error.response.data.errors);
    } else if (error.response.status === 401) {
      store.commit("auth/setUserData", null);
      localStorage.removeItem("authToken");
      localStorage.removeItem("user");
      router.push({ name: "Login" });
    } else {
      return Promise.reject(error);
    }
  }
);

axios.interceptors.request.use(function(config) {
  config.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  return config;
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
