var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-sheet',{staticClass:"overflow-hidden",staticStyle:{"position":"relative"}},[_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('router-view',{ref:"rview"})],1)],1),(_vm.user)?_c('v-navigation-drawer',{attrs:{"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":"https://randomuser.me/api/portraits/men/78.jpg"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.user.name))])])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.items),function(item,index){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isUserPermission(item.perms)),expression:"isUserPermission(item.perms)"}],key:index,attrs:{"link":"","disabled":item.isdisable,"to":item.url},on:{"click":function($event){return _vm.removeNotify(index)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chevron-right")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(item.title))]),(item.notify)?_c('v-badge',{staticClass:"ml-2",attrs:{"color":"red","right":"","content":item.notifyCount}}):_vm._e()],1)],1)],1)}),1)],1):_vm._e()],1),_c('v-app-bar',{attrs:{"app":""}},[(_vm.user)?_c('v-btn',{attrs:{"color":"pink","dark":""},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.drawer ? "mdi-menu" : "mdi-close")+" ")])],1):_vm._e(),(_vm.user)?_c('v-btn',{staticClass:"ml-2",attrs:{"dark":""},on:{"click":_vm.toggleTheme}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.isLightTheme ? "mdi-moon-waning-crescent" : "mdi-white-balance-sunny")+" ")])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.user)?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.$router.push({ name: 'Home' })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-broadcast")]),_vm._v(" "+_vm._s(_vm.onlineUsers.length)+" ")],1):_vm._e()]}}])},[_c('span',[_vm._v("Online User Count")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.user)?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$router.push({ name: 'Home' })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-broadcast")]),_vm._v(" "+_vm._s(_vm.onlineMembers.length)+" ")],1):_vm._e()]}}])},[_c('span',[_vm._v("Online Member Count")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.user)?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"orange","dark":"","href":"https://gowawe.com/","target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home-floor-g")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Gowawe.com")])]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","elevation":"0"},on:{"click":_vm.logout}},[_c('v-icon',[_vm._v("mdi-power")])],1),(!_vm.user)?_c('h5',[_vm._v("Please Login")]):_vm._e()],1),_c('v-footer',{attrs:{"app":""}},[_c('span',{staticClass:"d-block text-center w-100"},[_vm._v("Gowawe Awakens v1 © 2022 It is a Gowawe.com Product. ")])]),_c('v-bottom-sheet',{attrs:{"persistent":""},model:{value:(_vm.connection.statu),callback:function ($$v) {_vm.$set(_vm.connection, "statu", $$v)},expression:"connection.statu"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"200px"}},[_c('div',{staticClass:"pt-5"},[(_vm.connection.isconnect == false)?_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}}):_vm._e(),(_vm.connection.isconnect == true)?_c('v-icon',{attrs:{"color":"green","x-large":""}},[_vm._v("mdi-check")]):_vm._e(),_c('h5',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.connection.text))])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }